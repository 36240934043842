<template>
  <v-card class="ma-3">
    <v-col cols="12">
      <span style="font-size: 18px"> Ultimas sincronização realizadas </span>
      <br />
    </v-col>
    <v-dialog
      v-model="showLog"
      style="hidden"
      width="350px"
      scrollable
      hide-overlay
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-col cols="12">
            <span style="font-size: 18px"> Detalhes de Sincronização </span>
            <br />
            <span style="font-size: 15px"> {{ nomeUsuario }} </span>
          </v-col>
          <v-btn icon dark @click="showLog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text outlined>
          <div>
            <SincView v-if="showLog" :cod-usuario="codUsuario" />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" width="100" @click="showLog = false">
            <v-icon dark left> mdi-minus-circle </v-icon>
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="logs"
      :headers="headers"
      :actions="actions"
      :slots="['item.DataInicio', 'item.DataFim']"
      :search-init="search"
      :search.sync="search"
    >
      <template v-slot:[`item.DataInicio`]="{ item }">
        {{ moment(item.DataInicio).format('DD/MM/YYYY HH:mm:ss') }}
      </template>
      <template v-slot:[`item.DataFim`]="{ item }">
        {{
          item.DataFim != '-'
            ? moment(item.DataFim).format('DD/MM/YYYY HH:mm:ss')
            : '-'
        }}
      </template>
    </crud-list>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import SincView from './show/SincView.vue'
export default {
  components: {
    SincView,
  },
  data() {
    const viewDetails = {
      title: 'Detalhes de sincronização',
      color: 'primary',
      click: item => {
        this.codUsuario = String(item.CodUsuario)
        this.nomeUsuario = String(item.name)
        this.showLog = true
      },
      icon: 'mdi-information-outline',
    }
    let btnAction = []
    this.$user.get().role === 'admin'
      ? btnAction.push(viewDetails)
      : btnAction.push(viewDetails)
    return {
      searching: true,
      showLog: false,
      codUsuario: '',
      nomeUsuario: '',
      actions: btnAction,
      headers: [
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '200px',
          filterable: true,
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'email',
          width: '200px',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Inicio',
          value: 'DataInicio',
          width: '200px',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Fim',
          value: 'DataFim',
          width: '200px',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Duração (Segundos)',
          value: 'Duracao',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Código Versão',
          value: 'Versao',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Primeira sincronização?',
          value: 'PrimeiraSinc',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Usuário',
          value: 'type',
          width: 'auto',
        },
        {
          align: 'center',
          sortable: true,
          text: 'Status',
          value: 'Obs',
          width: 'auto',
        },
        {
          text: 'Ações',
          sortable: false,
          width: 'auto',
          value: 'actions',
          align: 'center',
        },
      ],
      loading: true,
      logs: [],
    }
  },
  computed: {
    ...mapState(['relatorio_search']),
    search: {
      get() {
        return null
      },
      set(val) {
        this.$store.commit('SET_RELATORIO_SEARCH', val)
      },
    },
  },
  mounted() {
    this.getLogs()
  },
  methods: {
    getLogs() {
      this.api.get.synclog().then(res => {
        res = res.map(r => {
          return {
            ...r,
            Duracao: this.diff(r.DataInicio, r.DataFim),
            PrimeiraSinc: this.firstSync(r.FirstSync),
            DataFim: this.checkDate(r.DataFim),
            Obs: this.checkObs(r.Obs),
          }
        })

        this.logs = res
        this.loading = false
      })
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    diff(DataInicio, DataFim) {
      if (DataFim != null) {
        var date1 = new Date(DataInicio)
        var date2 = new Date(DataFim)
        var timeDiff = Math.abs(date2.getTime() - date1.getTime())
        var diff = Math.ceil(timeDiff / 1000)
        return diff
      }
      return '-'
    },

    firstSync(firstSync) {
      if (firstSync) {
        return 'Sim'
      } else return 'Não'
    },

    checkDate(date) {
      if (date != null) {
        return date
      } else return '-'
    },

    checkObs(obs) {
      if (obs == 'COMPLETA') {
        return 'Concluída'
      }
      if (obs == '') {
        return 'Sincronização incompleta'
      } else return 'Falha ao sincronizar'
    },
  },
}
</script>
