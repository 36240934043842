<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12" style="font-size: 15px">
            <v-row justify="center"
              >Sincronizou {{ QtdLogs }} vezes no total.
            </v-row>
            <br />
            <v-row justify="center">A primeira sincronização foi em: </v-row>
            <v-row justify="center"
              >{{ moment(DataInicioMin).format('DD/MM/YYYY HH:mm:ss') }}.
            </v-row>
            <br />
            <v-row justify="center">A última sincronização foi em: </v-row>
            <v-row justify="center"
              >{{ moment(DataInicioMax).format('DD/MM/YYYY HH:mm:ss') }}.
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},

  props: {
    codUsuario: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      loading: true,
      log: null,
      QtdLogs: null,
      DataInicioMin: null,
      DataInicioMax: null,
    }
  },
  mounted() {
    if (!this.codUsuario) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/sinc`,
      })
    } else {
      this.getLogDetails()
    }
  },
  methods: {
    getLogDetails() {
      this.api.get.synclogdetails(this.codUsuario).then(data => {
        this.QtdLogs = data[0].QtdLogs
        this.DataInicioMin = data[0].DataInicioMin
        this.DataInicioMax = data[0].DataInicioMax
        this.loading = false
      })
    },
  },
}
</script>
